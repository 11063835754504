const languages = [
  { id: 1, name: 'Англійська' },
  { id: 2, name: 'Китайська' },
  { id: 3, name: 'Іспанська' },
  { id: 4, name: 'Гінді' },
  { id: 5, name: 'Арабська' },
  { id: 6, name: 'Французька' },
  { id: 7, name: 'Бенгальська' },
  { id: 8, name: 'Португальська' },
  { id: 9, name: 'Урду' },
  { id: 10, name: 'Німецька' },
  { id: 11, name: 'Японська' },
  { id: 12, name: 'Панджабі' },
  { id: 13, name: 'Яванська' },
  { id: 14, name: 'Телугу' },
  { id: 15, name: 'Марагі' },
  { id: 16, name: 'Тамільська' },
  { id: 17, name: 'Польська' },
  { id: 18, name: 'Чеська' },
  { id: 19, name: 'Грецька' },
  { id: 20, name: 'Італійська' },
  { id: 21, name: 'Маратхі' },
  { id: 22, name: 'Телугу' },
  { id: 23, name: 'Білоруська' },
  { id: 24, name: 'Нідерландська' },
  { id: 25, name: 'Суахілі' },
  { id: 26, name: 'Румунська' },
  { id: 27, name: 'Голландська' },
  { id: 28, name: 'Цюцен' },
];

export default languages;
