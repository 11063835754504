import QuestionForm from '@components/forms/questions/QuestionsForms';

const QuestionPage = () => {
  return (
    <>
      <QuestionForm />
    </>
  );
};

export default QuestionPage;
