import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';

import { formData } from './formData';
import instance from '@axiosConfig';
import Logo from '@assets/images/jobseeker-logo.svg';
import PersonalPage from '@pages/questions_pages/PersonalPage';
import GoalPage from '@pages/questions_pages/GoalPage';
import EducationPage from '@pages/questions_pages/EducationPage';
import SkillsPage from '@pages/questions_pages/SkillsPage';
import PhotoPage from '@pages/questions_pages/PhotoPage';
import ExperiencePage from '@pages/questions_pages/ExperiencePage';
import { toast } from 'react-toastify';

function QuestionForm() {
  const [shouldNavigate, setShouldNavigate] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);

  const [formDataState, setFormDataState] = useState(formData);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    const newValue = files ? files[0] : value;
    setFormDataState((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let educationData = {
      schoolName: formDataState.schoolName,
      specialization: formDataState.specialization,
      educationLevel: formDataState.educationLevel,
      additionalSchool: formDataState.additionalSchool,
      additionalSpecialization: formDataState.additionalSpecialization,
      additionalEducation: formDataState.additionalEducation,
      courses: formDataState.courses,
    };

    let formDataEducation = new FormData();
    for (const key in educationData) {
      formDataEducation.append(key, educationData[key]);
    }
    if (formDataState.cerificationfile) {
      formDataEducation.append('certificationfile', formDataState.certificationFile);
    }
    const responses = await Promise.all([
      instance.post('/form/user-information/', {
        first_name: formDataState.firstName,
        last_name: formDataState.lastName,
        phone_number: formDataState.phone,
        city: formDataState.location,
      }),
      instance.post('/form/user-goals/', {
        industry: formDataState.industry,
        position: formDataState.position,
        experience: formDataState.experience,
        achievements: formDataState.achievements,
        salary: formDataState.salary,
      }),
      instance.post('/form/user-experience/', {
        company: formDataState.company,
        companyIndustry: formDataState.companyIndustry,
        companyPosition: formDataState.companyPosition,
        employmentPeriod: formDataState.employmentPeriod,
        projects: formDataState.projects,
        additionalCompany: formDataState.additionalCompany,
        additionalIndustry: formDataState.additionalIndustry,
        additionalPosition: formDataState.additionalPosition,
        additionalEmploymentPeriod: formDataState.additionalEmploymentPeriod,
        additionalProjects: formDataState.additionalProjects,
      }),
      instance.post('/form/user-education/', educationData),

      instance.post('/form/user-skills/', {
        professionalSkills: formDataState.professionalSkills,
        hobbies: formDataState.hobbies,
        futureGoals: formDataState.futureGoals,
        languages: formDataState.languages,
        languageLevel: formDataState.languageLevel,
      }),
    ]);
    const all_responses = responses.every((responses) => responses.status === 200);

    if (all_responses) {
      setShouldNavigate(true);
    } else {
      toast.error('Щось трапилось не можливо відправити форму');
    }
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const backStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const totalSteps = 6;

  return (
    <>
      <div className="background"></div>
      <div className="container">
        {shouldNavigate ? <Navigate to="/dashboard/profile" /> : null}
        <div className="logo-container img">
          <Link to="/dashboard/profile">
            <img src={Logo} alt="Logo" className="logo" />
          </Link>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-12 ">
            {currentStep === 1 && (
              <PersonalPage formDataState={formDataState} handleChange={handleChange} />
            )}

            {currentStep === 2 && (
              <GoalPage formDataState={formDataState} handleChange={handleChange} />
            )}

            {currentStep === 3 && (
              <ExperiencePage formDataState={formDataState} handleChange={handleChange} />
            )}

            {currentStep === 4 && (
              <EducationPage formDataState={formDataState} handleChange={handleChange} />
            )}

            {currentStep === 5 && (
              <SkillsPage formDataState={formDataState} handleChange={handleChange} />
            )}

            {currentStep === 6 && <PhotoPage />}
          </div>
        </div>

        {/* Navigation buttons */}
        <div className="row justify-content-center">
          <div className="chose-block col-lg-6 col-md-8 col-12 mt-4">
            {currentStep === 1 && (
              <button className="btn btn-outline-primary btn-lg btn-back mt-3">
                <Link to="/questions">Назад</Link>
              </button>
            )}
            {currentStep > 1 && (
              <button className="btn btn-outline-primary btn-lg btn-back mt-3" onClick={backStep}>
                Назад
              </button>
            )}
            {currentStep < totalSteps && (
              <button className="btn btn-primary btn-lg btn-next mt-3" onClick={nextStep}>
                Наступне
              </button>
            )}
            {currentStep === 6 && (
              <button className="btn btn-primary btn-lg btn-next mt-3" onClick={handleSubmit}>
                Згенерувати
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default QuestionForm;
