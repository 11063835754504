import { useState } from 'react';

function usePhotoLoader(initialPhoto) {
  const [photoSrc, setPhotoSrc] = useState(initialPhoto);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAvatarLoad = (avatarData) => {
    setPhotoSrc(`data:image/jpeg;base64,${avatarData}`);
  };

  return { photoSrc, handleFileUpload, handleAvatarLoad };
}

export default usePhotoLoader;
