const cities = [
  { id: 1, name: 'Київ' },
  { id: 2, name: 'Львів' },
  { id: 3, name: 'Харків' },
  { id: 4, name: 'Одеса' },
  { id: 5, name: 'Дніпро' },
  { id: 6, name: 'Запоріжжя' },
  { id: 7, name: 'Івано-Франківськ' },
  { id: 8, name: 'Херсон' },
  { id: 9, name: 'Полтава' },
  { id: 10, name: 'Черкаси' },
  { id: 11, name: 'Чернівці' },
  { id: 12, name: 'Житомир' },
  { id: 13, name: 'Миколаїв' },
  { id: 14, name: 'Суми' },
  { id: 15, name: 'Вінниця' },
  { id: 16, name: 'Тернопіль' },
  { id: 17, name: 'Рівне' },
  { id: 18, name: 'Чернігів' },
  { id: 19, name: 'Ужгород' },
  { id: 20, name: 'Кропивницький' },
  { id: 21, name: 'Хмельницький' },
  { id: 22, name: 'Луцьк' },
  { id: 23, name: 'Маріуполь' },
  { id: 24, name: 'Севастополь' },
  { id: 25, name: 'Івано-Франківськ' },
  { id: 26, name: 'Полтава' },
  { id: 27, name: 'Хмельницький' },
  { id: 28, name: 'Чернівці' },
  { id: 29, name: "Слов'янськ" },
  { id: 30, name: 'Краматорськ' },
  { id: 31, name: 'Херсон' },
  { id: 32, name: 'Луганськ' },
  { id: 33, name: 'Мелітополь' },
  { id: 34, name: 'Нікополь' },
  { id: 35, name: 'Бердянськ' },
  { id: 36, name: 'Маріуполь' },
  { id: 37, name: "Слов'янськ" },
  { id: 38, name: 'Алчевськ' },
  { id: 39, name: 'Лисичанськ' },
  { id: 40, name: 'Сєвєродонецьк' },
  { id: 41, name: 'Стаханов' },
  { id: 42, name: 'Жовті Води' },
  { id: 43, name: 'Дружківка' },
  { id: 44, name: 'Енергодар' },
  { id: 45, name: 'Кременчук' },
  { id: 46, name: 'Кривий Ріг' },
  { id: 47, name: "Куп'янськ" },
  { id: 48, name: 'Марганець' },
  { id: 49, name: 'Мариуполь' },
  { id: 50, name: 'Павлоград' },
  { id: 51, name: 'Первомайськ' },
  { id: 52, name: 'Покров' },
  { id: 53, name: "Слов'янськ" },
  { id: 54, name: 'Шостка' },
  { id: 55, name: 'Артемівськ' },
  { id: 56, name: 'Світлодарськ' },
  { id: 57, name: "Слов'янськ" },
  { id: 58, name: 'Сніжне' },
  { id: 59, name: 'Торез' },
  { id: 60, name: 'Харцизьк' },
  { id: 61, name: 'Анапа' },
  { id: 62, name: 'Бердянськ' },
  { id: 63, name: 'Керч' },
  { id: 64, name: 'Симферополь' },
  { id: 65, name: 'Евпаторія' },
  { id: 66, name: 'Ялта' },
  { id: 67, name: 'Керч' },
  { id: 68, name: 'Сімферополь' },
  { id: 69, name: 'Феодосія' },
  { id: 70, name: 'Джанкой' },
  { id: 71, name: 'Красноперекопськ' },
  { id: 72, name: 'Армянськ' },
  { id: 73, name: 'Мелітополь' },
  { id: 74, name: 'Нова Каховка' },
  { id: 75, name: 'Скадовськ' },
  { id: 76, name: 'Горлівка' },
  { id: 77, name: 'Дзержинськ' },
  { id: 78, name: 'Сніжне' },
  { id: 79, name: 'Торез' },
  { id: 80, name: "Слов'янськ" },
  { id: 82, name: 'Новоросійськ' },
  { id: 83, name: 'Туапсе' },
  { id: 84, name: 'Геленджик' },
  { id: 85, name: 'Кабардинка' },
  { id: 86, name: 'Темрюк' },
  { id: 87, name: 'Тимашівськ' },
  { id: 88, name: 'Щербинівка' },
  { id: 89, name: 'Судак' },
  { id: 90, name: 'Феодосія' },
  { id: 91, name: 'Коктебель' },
  { id: 92, name: 'Алушта' },
  { id: 93, name: 'Сімферополь' },
  { id: 94, name: 'Бахчисарай' },
  { id: 95, name: 'Євпаторія' },
  { id: 96, name: 'Саки' },
  { id: 97, name: 'Керч' },
  { id: 98, name: 'Армянськ' },
  { id: 99, name: 'Джанкой' },
  { id: 100, name: 'Совєтська' },
];

export default cities;
