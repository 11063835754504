import Navbar from '@components/navbar/Navbar';
import HeroBlock from '@components/home_page/HeroBlock';
import AdvantagesBlock from '@components/home_page/AdvantagesBlock';
import AboutUsBlock from '@components/home_page/AboutUsBlock';
import QuestionsBlock from '@components/home_page/QuestionsBlock';
import Footer from '@components/footer/Footer';

const HomePage = () => {
  return (
    <>
      <div className="container">
        <Navbar />
        <HeroBlock />
        <AdvantagesBlock />
        <AboutUsBlock />
        <QuestionsBlock />
        <div className="footer-space"></div>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
