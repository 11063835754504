import { useState } from 'react';

const useFormSubmit = (callback) => {
  const [formData, setFormData] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (callback) {
      callback(formData);
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    setFormData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value,
    }));
  };

  return {
    formData,
    handleSubmit,
    handleInputChange,
  };
};

export default useFormSubmit;
