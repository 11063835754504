import { Translation } from 'i18nano';

import Navbar from '@components/navbar/Navbar';
import Footer from '@components/footer/Footer';

const EmployeePage = () => {
  return (
    <>
      <div className="container ">
        <Navbar />
        <h2 className="mb-4 mt-5 title-5">
          <Translation path="How does our platform help job seekers?" />
        </h2>

        <div className="mb-4">
          <h4 className="title-6">
            <Translation path="Quick job finding" />
          </h4>
          <p className="text-3">
            <Translation path="Our platform helps you quickly find jobs that match your skills and interests. With the help of a powerful search engine, you can easily find the vacancies that interest you." />
          </p>
        </div>

        <div className="mb-4">
          <h4 className="title-6">
            <Translation path="Minimum time to fill out a resume" />
          </h4>
          <p className="text-3">
            <Translation path="We make the process of creating a resume as simple and fast as possible. You can use our tools to automatically generate resumes based on your data and target job, which helps save you time." />
          </p>
        </div>

        <div className="mb-4">
          <h4 className="title-6">
            <Translation path="Automatic generation of resumes for vacancies" />
          </h4>
          <p className="text-3">
            <Translation path="Our platform allows you to automatically generate a resume that is perfectly suited to a specific vacancy. You can quickly tailor your CV for each job opening to increase your chances of a successful hire." />
          </p>
        </div>
        <div className="footer-space"></div>
      </div>
      <Footer />
    </>
  );
};

export default EmployeePage;
