import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: localStorage.getItem('lang') || 'uk',
};

export const currantLangSlice = createSlice({
  name: 'currantLang',
  initialState,
  reducers: {
    switchLang: (state, action) => {
      state.value = action.payload;
      localStorage.setItem('lang', action.payload);
    },
  },
});

export const { switchLang } = currantLangSlice.actions;

export default currantLangSlice.reducer;
