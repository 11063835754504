import { useState, useEffect } from 'react';
import instance from '@axiosConfig';

const useAvatarStatus = () => {
  const [avatarInfo, setAvatarInfo] = useState({
    hasAvatar: false,
    avatarData: null,
  });

  useEffect(() => {
    const checkAvatar = async () => {
      try {
        const response = await instance.get('/api/check_avatar');

        if (response.data.hasAvatar) {
          const imageUrl = `data:image/jpeg;base64,${response.data.avatarData}`;
          setAvatarInfo({
            hasAvatar: true,
            avatarData: imageUrl,
          });
        } else {
          setAvatarInfo({
            hasAvatar: false,
            avatarData: null,
          });
        }
      } catch (error) {
        console.error('Error checking avatar', error);
        setAvatarInfo({
          hasAvatar: false,
          avatarData: null,
        });
      }
    };

    checkAvatar();
  }, []);

  return avatarInfo;
};

export default useAvatarStatus;
