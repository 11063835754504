import React, { useState } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Translation, useTranslation } from 'i18nano';

const LoginForm = () => {
  const t = useTranslation();

  const [userCredentials, setUserCredentials] = useState({
    email: '',
    password: '',
  });

  const [loginSuccess, setLoginSuccess] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/login`,
        userCredentials,
      );
      console.log(response.data);
      if (response.data.success) {
        document.cookie = `refresh_token=${response.data.refresh_token}`;
        localStorage.setItem('access_token', response.data.access_token);
        setLoginSuccess(true);
      }
    } catch (error) {
      toast.error('Неправильні данні');
      console.error(error);
    }
  };

  return (
    <div>
      {loginSuccess ? (
        <Navigate to="/dashboard/profile" />
      ) : (
        <form onSubmit={handleLogin}>
          <div className="mb-1 ">
            <input
              type="email"
              placeholder={t('Your mail')}
              value={userCredentials.email}
              onChange={(e) =>
                setUserCredentials((prevCredentials) => ({
                  ...prevCredentials,
                  email: e.target.value,
                }))
              }
              className="input-field"
              name="email"
              autoComplete="email"
            />
          </div>
          <div className="mb-1">
            <input
              type="password"
              placeholder={t('Password')}
              value={userCredentials.password}
              onChange={(e) =>
                setUserCredentials((prevCredentials) => ({
                  ...prevCredentials,
                  password: e.target.value,
                }))
              }
              className="input-field"
              name="password"
              autoComplete="current-password"
            />
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="button-create-account">
              <Translation path="Confirm" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="34"
                viewBox="0 0 35 34"
                fill="none"
              >
                <path
                  d="M10.0001 10.5209L11.5276 8.99998L20.0533 17.4886L11.5631 25.9771L10.0356 24.4563L17.0338 17.4886L10.0001 10.5209Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default LoginForm;
