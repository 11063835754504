import { Translation } from 'i18nano';

import usePhotoLoader from '@hooks/photoForm/usePhotoLoader';
import Photo from '@assets/images/photo_page/photo.svg';

import FileUploader from '@hooks/photoForm/fileUploader';

const PhotoPage = () => {
  const { photoSrc, handleFileUpload } = usePhotoLoader(Photo);

  const { uploadToServer } = FileUploader({
    endpoint: 'http://localhost:5000/api/upload/avatar/',
  });

  const handleInputChange = async (e) => {
    await handleFileUpload(e);
    const file = e.target.files[0];
    await uploadToServer(file);
  };

  return (
    <>
      <h1 className="form-header">
        <Translation path="Photo" />
      </h1>
      <form className="questions-form mt-4">
        <div className="w-100 mb-2 text-center">
          <img src={photoSrc} alt="Profile" className="photo-icon" />{' '}
        </div>
        <div className="w-100 my-4">
          <input type="file" accept=".JPG, .PNG" id="upload" hidden onChange={handleInputChange} />
          <label className="btn btn-outline-primary btn-lg upload-button w-100" htmlFor="upload">
            <Translation path="Upload a photo" />
          </label>
        </div>
        <p className="w-100 mb-2">
          <strong>
            <Translation path="Recommendations for choosing a photo:" />
          </strong>{' '}
          <Translation path="to avoid spoiling the impression, it's better to choose a modest business photo where the face is well visible. Avoid selecting photos in the style of (further in quotes): 'I'm a model,' 'with tights on the head,' 'in dark sunglasses,' 'nude,' 'with a bottle and in a T-shirt,' 'on the beach,' 'with a cat,' 'on a horse,' 'me and my wife. wedding photo,' 'selfie from a party.' If you're not sure if the picture is suitable, it's better not to add it." />
        </p>
      </form>
    </>
  );
};

export default PhotoPage;
