import { Link } from 'react-router-dom';
import { Translation } from 'i18nano';

import LoopIcon from '@assets/images/dashboard/managers_page/loop.svg';

const Managers = () => {
  return (
    <>
      <header className="dashboard-header">
        <div className="breadcrumbs">
          <Link to="/dashboard/profile">
            <Translation path="Main page" />
          </Link>{' '}
          /{' '}
          <Link to="/dashboard/managers">
            <Translation path="Managers" />
          </Link>
          <h1 className="profile-heading">
            <Translation path="Managers" />
          </h1>
        </div>
        <form className="search-container d-none d-md-block">
          <input type="search" placeholder="Пошук..." className="btn-search" />
          <button type="submit" className="search-icon">
            <img src={LoopIcon} alt="Search icon"></img>
          </button>
        </form>
      </header>
      <div className="d-flex px-4">
        <h1 className="mt-5">
          <Translation path="You have not yet responded to the vacancy" />
        </h1>
      </div>
    </>
  );
};
export default Managers;
