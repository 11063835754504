import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Translation, useTranslation } from 'i18nano';

const RegistrationForm = () => {
  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    password: '',
  });
  const navigate = useNavigate();

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/signup`, newUser);
      setNewUser({ name: '', email: '', password: '' });
      if (response.data.success) {
        navigate('/questions');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const t = useTranslation();

  // Registration Form
  return (
    <form onSubmit={handleCreateUser}>
      <div className="mb-1">
        <input
          type="text"
          placeholder={t('Your name')}
          value={newUser.name}
          onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
          className="input-field"
          name="firstname"
          autoComplete="given-name"
        />
      </div>
      <div className="mb-1 ">
        <input
          type="email"
          placeholder={t('Your mail')}
          value={newUser.email}
          onChange={(e) =>
            setNewUser({
              ...newUser,
              email: e.target.value,
            })
          }
          className="input-field"
          name="email"
          autoComplete="email"
        />
      </div>
      <div className="mb-1">
        <input
          type="password"
          placeholder={t('Password')}
          value={newUser.password}
          onChange={(e) =>
            setNewUser({
              ...newUser,
              password: e.target.value,
            })
          }
          className="input-field"
          autoComplete="off"
          name="password"
        />
      </div>
      <div className="checkbox-field mb-3 form-check">
        <input type="checkbox" required className="form-check-input" id="termsCheckbox" />
        <label className="form-check-label" htmlFor="termsCheckbox">
          <Translation path="Creating an account means you agree to ours" />{' '}
          <a href="/terms">
            <Translation path="Terms of service" />
          </a>
          ,{' '}
          <a href="/policy">
            <Translation path="Privacy policy" />
          </a>{' '}
          <Translation path="and default notification settings." />
        </label>
      </div>
      <div className="d-flex justify-content-center">
        <button type="submit" className="button-create-account">
          <Translation path="Create an account" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="34"
            viewBox="0 0 35 34"
            fill="none"
          >
            <path
              d="M10.0001 10.5209L11.5276 8.99998L20.0533 17.4886L11.5631 25.9771L10.0356 24.4563L17.0338 17.4886L10.0001 10.5209Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </form>
  );
};

export default RegistrationForm;
