import { Translation, useTranslation } from 'i18nano';

const ExperiencePage = ({ formDataState, handleChange }) => {
  const t = useTranslation();

  return (
    <>
      <h1 className="form-header">
        <Translation path="Experience" />
      </h1>
      <div className="accordion mt-4 ">
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              <span className="form-text">
                <Translation path="Position" />
              </span>
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseOne"
            className="accordion-collapse collapse show px-3"
            aria-labelledby="panelsStayOpen-headingOne"
          >
            <div className="accordion-body">
              <form className="questions-form">
                <div className="mt-4 mb-3 w-100">
                  <label htmlFor="company" className="form-label">
                    <Translation path="Specify the company or organization in which you work/worked *" />
                  </label>
                  <input
                    type="text"
                    id="company"
                    className="form-control input-field"
                    placeholder={t('Input the text')}
                    name="company"
                    value={formDataState.company}
                    onChange={handleChange}
                  />
                  <div className="form-text-2">
                    <Translation path="* start with the last company in which you worked/are working" />
                  </div>
                </div>
                <div className="mb-2 w-100">
                  <label htmlFor="companyIndustry" className="form-label">
                    <Translation path="Specify the field of activity of this company" />
                  </label>
                  <input
                    type="text"
                    id="companyIndustry"
                    className="form-control input-field"
                    placeholder={t('Input the text')}
                    name="companyIndustry"
                    value={formDataState.companyIndustry}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-2 w-100">
                  <label htmlFor="companyPosition" className="form-label">
                    <Translation path="What position did you hold/hold in the specified company?" />
                  </label>
                  <input
                    type="text"
                    id="companyPosition"
                    className="form-control input-field"
                    placeholder={t('Input the text')}
                    name="companyPosition"
                    value={formDataState.companyPosition}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-2 w-100">
                  <label htmlFor="employmentPeriod" className="form-label">
                    <Translation path="Select the period of work in the specified company" />
                  </label>
                  <select
                    className="form-select input-field"
                    id="employmentPeriod"
                    name="employmentPeriod"
                    value={formDataState.employmentPeriod}
                    onChange={handleChange}
                  >
                    <option disabled value="">
                      <Translation path="Select from the list" />
                    </option>
                    <option value="1">
                      <Translation path="less than 1 year" />
                    </option>
                    <option value="2">
                      1 - 2 <Translation path="years" />
                    </option>
                    <option value="3">
                      <Translation path="years" />3 - 4 <Translation path="years" />
                    </option>
                    <option value="4">
                      <Translation path="5+ years" />
                    </option>
                  </select>
                </div>
                <div className="mb-2 w-100">
                  <label htmlFor="projects" className="form-label">
                    <Translation path="Tell about your projects and results of work in this company" />
                  </label>
                  <textarea
                    className="form-control input-textarea"
                    rows="3"
                    id="projects"
                    placeholder={t('Input the text')}
                    name="projects"
                    value={formDataState.projects}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion" id="additional-accordion">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseTwo"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseTwo"
            >
              <span className="form-text">
                <Translation path="Add position" />
              </span>
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseTwo"
            className="accordion-collapse collapse px-3"
            aria-labelledby="panelsStayOpen-headingTwo"
          >
            <div className="accordion-body">
              <form className="questions-form">
                <div className="mt-4 mb-2 w-100">
                  <label htmlFor="additionalCompany" className="form-label">
                    <Translation path="Specify the company or organization in which you work/worked *" />
                  </label>
                  <input
                    type="text"
                    id="additionalCompany"
                    className="form-control input-field"
                    placeholder={t('Input the text')}
                    name="additionalCompany"
                    value={formDataState.additionalCompany}
                    onChange={handleChange}
                  />
                  <div className="form-text-2">
                    <Translation path="worked/are working" />
                  </div>
                </div>
                <div className="mb-2 w-100">
                  <label htmlFor="additionalIndustry" className="form-label">
                    <Translation path="Specify the field of activity of this company" />
                  </label>
                  <input
                    type="text"
                    id="additionalIndustry"
                    className="form-control input-field"
                    placeholder={t('Input the text')}
                    name="additionalIndustry"
                    value={formDataState.additionalIndustry}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-2 w-100">
                  <label htmlFor="additionalPosition" className="form-label">
                    <Translation path="What position did you hold/hold in the specified company?" />
                  </label>
                  <input
                    type="text"
                    id="additionalPosition"
                    className="form-control input-field"
                    placeholder={t('Input the text')}
                    name="additionalPosition"
                    value={formDataState.additionalPosition}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-2 w-100">
                  <label htmlFor="additionalEmploymentPeriod" className="form-label">
                    <Translation path="Select the period of work in the specified company" />
                  </label>
                  <select
                    className="form-select input-field"
                    name="additionalEmploymentPeriod"
                    id="additionalEmploymentPeriod"
                    value={formDataState.additionalEmploymentPeriod}
                    onChange={handleChange}
                  >
                    <option disabled value="">
                      <Translation path="Select from the list" />
                    </option>
                    <option value="1">
                      <Translation path="less than 1 year" />
                    </option>
                    <option value="2">
                      1 - 2 <Translation path="years" />
                    </option>
                    <option value="3">
                      <Translation path="years" />3 - 4 <Translation path="years" />
                    </option>
                    <option value="4">
                      <Translation path="5+ years" />
                    </option>
                  </select>
                </div>
                <div className="mb-2 w-100">
                  <label htmlFor="additionalProjects" className="form-label">
                    <Translation path="aaaa" />
                  </label>
                  <textarea
                    className="form-control input-textarea"
                    rows="3"
                    id="additionalProjects"
                    placeholder={t('Input the text')}
                    name="additionalProjects"
                    value={formDataState.additionalProjects}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExperiencePage;
