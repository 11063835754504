import Logo from '@assets/images/jobseeker-logo.svg';
import { Link } from 'react-router-dom';

const QuestionsBegin = () => {
  return (
    <div className="container">
      <div className="logo-container img">
        <Link to="/dashboard/profile">
          <img src={Logo} alt="Logo" className="logo" />
        </Link>
      </div>

      {/* Question Block */}
      <div className="background"></div>
      <div className="form-container gap-5">
        <h1 className="title-3">
          Перед початком перегляду вакансій дайте відповіді на запитання та створіть своє найкраще
          резюме.
        </h1>
        <div className="button-block mt-4">
          <Link to="/questions-form">
            <button className="btn btn-primary btn-lg btn-next-begin">Почати</button>
          </Link>
          <Link to="/dashboard/profile">
            <button className="btn btn-outline-primary btn-lg btn-back">Пропустити</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default QuestionsBegin;
