import React from 'react';
import { Translation } from 'i18nano';

import Navbar from '@components/navbar/Navbar';
import Footer from '@components/footer/Footer';

const TermsOfService = () => {
  return (
    <>
      <div className="container">
        <Navbar />
        <h2 className="mb-4 mt-5 title-5">
          <Translation path="Terms of use" />
        </h2>
        <p className="mb-4 text-3">
          <Translation path="These Terms of Use govern your access to and use of our website and the services, information and materials we provide on this website. Please read these Terms carefully before using our Services." />
        </p>

        <h4 className="title-6">
          <Translation path="Acceptance of Terms" />
        </h4>
        <p className="text-3">
          <Translation path="By using our Services, you agree to these Terms. If you do not agree to these Terms, you may not use our Services." />
        </p>

        <h4 className="title-6">
          <Translation path="Changes to the Terms" />
        </h4>
        <p className="text-3">
          <Translation path="We may change these Terms from time to time. Any changes will take effect from the moment they are published on this page. By continuing to use our Services after changes are made, you agree to the changed Terms." />
        </p>

        <h4 className="title-6">
          <Translation path="Using our Services" />
        </h4>
        <p className="text-3">
          <Translation path="You agree to use our Services in accordance with all applicable laws and regulations. You also agree not to use our Services for any illegal or unauthorized purpose or to violate these Terms." />
        </p>

        <h4 className="title-6">
          <Translation path="Responsibility" />
        </h4>
        <p className="text-3">
          <Translation path="We are not responsible for any loss or damage incurred as a result of using our Services. We are not responsible for the quality, accuracy or reliability of any materials, information or services provided by third parties." />
        </p>

        <h4 className="title-6">
          <Translation path="Completion" />
        </h4>
        <p className="text-3">
          <Translation path="We may terminate or suspend our Services under any circumstances, including violation of these Terms." />
        </p>

        <h4 className="title-6">
          <Translation path="Contact Information" />
        </h4>
        <p className="text-3">
          <Translation path="If you have any questions about these Terms of Use, please contact us at jobseeker@gmail.com." />
        </p>
        <div className="footer-space"></div>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfService;
