import { Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useTranslationChange } from 'i18nano';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './styles/custom.sass';
import ProtectedRoute from '@components/protected/ProtectedRoute';

import LoginPage from '@pages/login/LoginPage';
import RegistrationPage from '@pages/registration/RegistrationPage';
import ScrollToTop from '@components/scroll/ScrollToTop';

//HomePage
import HomePage from '@pages/home_page/HomePage';
import PageNotFound from '@pages/404/PageNotFound';
import AboutUs from '@pages/about/AboutUs';
import PrivatePolicy from '@pages/policy/PrivatePolicy';
import FAQ from '@pages/faq/Faq';
import TermsOfService from '@pages/terms/TermsOfService';
import Advantages from '@pages/advantages/Advantages';
import EmployerPage from '@pages/employer/EmployerPage';
import EmployeePage from '@pages/employee/EmployeePage';

//QuestionPage
import QuestionPage from '@pages/questions_pages/QuestionPage';
import QuestionsBegin from '@pages/questions_pages/QuestionsBegin';

//Dashboard
import DashBoard from '@pages/dashboard/DashBoard';
import Profile from '@components/dashboard/Profile';
import Managers from '@components/dashboard/Managers';
import Soon from '@components/dashboard/Soon';
import { useEffect } from 'react';

const App = () => {
  const currantLang = useSelector((state) => state.currantLang.value);

  const translation = useTranslationChange();

  useEffect(() => {
    translation.change(currantLang);
  });

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/registration" element={<RegistrationPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/policy" element={<PrivatePolicy />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/advantages" element={<Advantages />} />
        <Route path="/employer" element={<EmployerPage />} />
        <Route path="/employee" element={<EmployeePage />} />

        {/* Private routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/questions" element={<QuestionsBegin />} />
          <Route path="/questions-form" element={<QuestionPage />} />

          <Route path="/dashboard" element={<DashBoard />}>
            <Route path="profile" element={<Profile />} />
            <Route path="managers" element={<Managers />} />
            <Route path="soon" element={<Soon />} />
          </Route>
        </Route>

        {/* Handle 404 */}
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <ToastContainer />
    </div>
  );
};

export default App;
