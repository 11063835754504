import { Translation, useTranslation } from 'i18nano';

import languages from '@data/languages';

const SkillsPage = ({ formDataState, handleChange }) => {
  const t = useTranslation();

  return (
    <>
      <h1 className="form-header">
        <Translation path="Skills and hobbies" />
      </h1>
      <form className="questions-form">
        <div className="mt-4 mb-2 w-100 mobile-field ">
          <label htmlFor="professionalSkillsInput" className="form-label">
            <Translation path="Tell about your professional skills" />
          </label>
          <textarea
            id="professionalSkillsInput"
            name="professionalSkills"
            className="form-control input-textarea"
            rows="3"
            placeholder={t('Input the text')}
            value={formDataState.professionalSkills}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="mb-2 w-100 mobile-field">
          <label htmlFor="hobbiesInput" className="form-label">
            <Translation path="Tell about your hobbies" />
          </label>
          <textarea
            id="hobbiesInput"
            name="hobbies"
            className="form-control input-textarea"
            rows="3"
            placeholder={t('Input the text')}
            value={formDataState.hobbies}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="mb-2 w-100 mobile-field">
          <label htmlFor="futureGoalsInput" className="form-label">
            <Translation path="Tell about your future goals and desires" />
          </label>
          <textarea
            id="futureGoalsInput"
            name="futureGoals"
            className="form-control input-textarea"
            rows="3"
            placeholder={t('Input the text')}
            value={formDataState.futureGoals}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="mb-2 w-100 mobile-field">
          <label htmlFor="languagesSelect" className="form-label">
            <Translation path="Foreign languages you know" />
          </label>
          <select
            id="languagesSelect"
            name="languages"
            className="form-select input-field"
            value={formDataState.languages}
            onChange={handleChange}
          >
            <option value="">
              <Translation path="Select from the list" />
            </option>
            {languages.map((language) => (
              <option key={language.id} value={language.id}>
                {language.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-2 w-100 mobile-field">
          <label htmlFor="languageLevelSelect" className="form-label">
            <Translation path="Select a level" />
          </label>
          <select
            id="languageLevelSelect"
            name="languageLevel"
            className="form-select input-field"
            value={formDataState.languageLevel}
            onChange={handleChange}
          >
            <option value="">
              <Translation path="Select from the list" />
            </option>

            <option value="Beginner">
              <Translation path="Elementary" />
            </option>

            <option value="Intermediate">
              <Translation path="Intermediate" />
            </option>

            <option value="Upper-Intermediate">
              <Translation path="Upper-Intermediate" />
            </option>

            <option value="Advanced">
              <Translation path="Advanced" />
            </option>

            <option value="Proficient">
              <Translation path="Proficiency" />
            </option>
          </select>
        </div>
      </form>
    </>
  );
};

export default SkillsPage;
