import { toast } from 'react-toastify';
import { Translation, useTranslation } from 'i18nano';

import institutions from '@data/institutions';

const EducationPage = ({ formDataState, handleChange }) => {
  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];

    if (uploadedFile) {
      toast.success('Файл прикріплено');
    }

    handleChange(event);
  };

  const t = useTranslation();

  return (
    <>
      <h1 className="form-header">
        <Translation path="Education" />
      </h1>
      <div className="accordion mt-4" id="education-accordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              <Translation path="Educational institution" />
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="panelsStayOpen-headingOne"
          >
            <div className="accordion-body">
              <form className="questions-form">
                <div className="mt-4 mb-2 w-100">
                  <label className="form-label">
                    <Translation path="Select the educational institution where you studied" />
                  </label>
                  <select
                    className="form-select input-field"
                    name="schoolName"
                    value={formDataState.schoolName}
                    onChange={handleChange}
                  >
                    <option value="">
                      <Translation path="Select from the list" />
                    </option>
                    {institutions.map((institution) => (
                      <option key={institution.id} value={institution.id}>
                        {institution.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-2 w-100">
                  <label className="form-label">
                    <Translation path="Specify specialization" />
                  </label>
                  <input
                    type="text"
                    className="form-control input-field"
                    placeholder={t('Input the text')}
                    name="specialization"
                    value={formDataState.specialization}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-2 w-100">
                  <label className="form-label">
                    <Translation path="Select a level" />
                  </label>
                  <select
                    className="form-select input-field"
                    name="educationLevel"
                    value={formDataState.educationLevel}
                    onChange={handleChange}
                  >
                    <option value="">
                      <Translation path="Select from the list" />
                    </option>
                    <option value="School">
                      <Translation path="Secondary education" />
                    </option>
                    <option value="Bachelors">
                      <Translation path="Bachelor" />
                    </option>
                    <option value="Masters">
                      <Translation path="Master's degree" />
                    </option>
                    <option value="Masters">
                      <Translation path="Doctor" />
                    </option>
                  </select>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* New additional education block */}
        <div className="accordion" id="additional-accordion">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                <Translation path="Add an educational institution" />
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingTwo"
            >
              <div className="accordion-body">
                <form className="questions-form">
                  <div className="mt-4 mb-2 w-100">
                    <label className="form-label">
                      <Translation path="Select the educational institution where you studied" />
                    </label>
                    <select
                      name="additionalSchool"
                      className="form-select input-field"
                      value={formDataState.additionalSchool}
                      onChange={handleChange}
                    >
                      <option value="">
                        <Translation path="Select from the list" />
                      </option>
                      {institutions.map((institution) => (
                        <option key={institution.id} value={institution.id}>
                          {institution.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-2 w-100">
                    <label className="form-label">
                      <Translation path="Specify specialization" />
                    </label>
                    <input
                      type="text"
                      name="additionalSpecialization"
                      className="form-control input-field"
                      placeholder={t('Input the text')}
                      value={formDataState.additionalSpecialization}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2 w-100">
                    <label className="form-label">
                      <Translation path="Select a level" />
                    </label>
                    <select
                      className="form-select input-field"
                      name="additionalEducation"
                      value={formDataState.additionalEducation}
                      onChange={handleChange}
                    >
                      <option value="">
                        <Translation path="Select from the list" />
                      </option>
                      <option value="School">
                        <Translation path="Secondary education" />
                      </option>
                      <option value="Bachelors">
                        <Translation path="Bachelor" />
                      </option>
                      <option value="Masters">
                        <Translation path="Master's degree" />
                      </option>
                      <option value="Masters">
                        <Translation path="Doctor" />
                      </option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Additional education block */}

        <div className="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header " id="panelsStayOpen-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseThree"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseThree"
              >
                <Translation path="Additional education" />
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree"
            >
              <div className="accordion-body">
                <form className="questions-form">
                  <div className="mt-4 mb-4 w-100">
                    <label htmlFor="projects" className="form-label">
                      <Translation path="Tell us about the courses, trainings, seminars" />
                    </label>
                    <textarea
                      className="form-control input-textarea"
                      rows="3"
                      placeholder={t('Input the text')}
                      name="courses"
                      value={formDataState.courses}
                      onChange={handleChange}
                    ></textarea>
                    <div className="mt-3 upload-container">
                      <input
                        type="file"
                        accept=".pdf"
                        id="upload"
                        hidden
                        onChange={handleFileChange}
                        value={formDataState.certificationFile}
                      />
                      <label
                        className="btn btn-outline-primary btn-lg upload-button"
                        htmlFor="upload"
                      >
                        <Translation path="Download diploma/certificate" />
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EducationPage;
