import { configureStore } from '@reduxjs/toolkit';
import burgerMenuReducer from './burgerMenu/burgerMenuSlice';
import currantLangReducer from './currantLang/currantLangSlice';

export const store = configureStore({
  reducer: {
    burgerMenu: burgerMenuReducer,
    currantLang: currantLangReducer,
  },
});
