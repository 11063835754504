import { useState, useEffect } from 'react';
import instance from '@axiosConfig';
import { toast } from 'react-toastify';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await instance.get('/api/authenticate/');

        if (response.status === 200) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
        toast.error('Помилка при перевірці авторизації');
      }
    };

    checkAuthentication();
  }, []);

  return isAuthenticated;
};

export default useAuth;
