import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { Translation, useTranslation } from 'i18nano';

import instance from '@axiosConfig';
import usePhotoLoader from '@hooks/photoForm/usePhotoLoader';
import UserAvatar from '@assets/images/dashboard/user_image.svg';
import UploadIcon from '@assets/images/dashboard/profile_page/upload.svg';
import FileUploader from '@hooks/photoForm/fileUploader';
import useAvatarStatus from '@hooks/photoForm/checkAvatar';
import useFormSubmit from '@hooks/form/formSubmit';

import { toast } from 'react-toastify';

const Profile = () => {
  const { formData, handleSubmit, handleInputChange } = useFormSubmit(async (data) => {
    const response = await instance.post('/form/user-information', data);
    if (response.status === 200) {
      toast.success('Дані успішно оновлено');
    } else {
      toast.error('Щось пішло не так');
    }
  });

  const { hasAvatar, avatarData } = useAvatarStatus();

  const { photoSrc, handleFileUpload, handleAvatarLoad } = usePhotoLoader();

  const { uploadToServer } = FileUploader({
    endpoint: `${process.env.REACT_APP_API_ENDPOINT}/api/upload/avatar/`,
  });

  const t = useTranslation();

  useEffect(() => {
    if (hasAvatar) {
      handleAvatarLoad(avatarData.replace('data:image/jpeg;base64,', ''));
    }
  }, [hasAvatar, avatarData, handleAvatarLoad]);

  const handleFileChange = async (e) => {
    await handleFileUpload(e);
    const file = e.target.files[0];
    await uploadToServer(file);
  };

  return (
    <>
      <header className="dashboard-header">
        <div className="breadcrumbs">
          <Link to="/dashboard/profile">
            <Translation path="Main page" />
          </Link>{' '}
          /{' '}
          <Link to="/dashboard/profile">
            <Translation path="Profile" />
          </Link>
          <h1 className="profile-heading">
            <Translation path="Profile" />
          </h1>
        </div>
      </header>
      <div className="row mt-md-4 gy-4 flex-column-reverse flex-md-row ">
        <div className="col-md-6">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="InputName">
                <Translation path="First Name" />
              </label>
              <input
                type="text"
                className="form-control form-profile-field"
                id="InputName"
                autoComplete="given-name"
                name="first_name"
                aria-describedby="first_name"
                placeholder={t('Your name')}
                onChange={handleInputChange}
                value={formData.firstName}
              />
            </div>
            <div className="form-group">
              <label htmlFor="InputSurName">
                <Translation path="Last Name" />
              </label>
              <input
                type="text"
                className="form-control form-profile-field"
                id="InputSurName"
                name="last_name"
                aria-describedby="last_name"
                placeholder={t('Your surname')}
                onChange={handleInputChange}
                value={formData.lastName}
              />
            </div>
            <div className="form-group">
              <label htmlFor="InputPhone">
                <Translation path="Phone number" />
              </label>
              <input
                type="number"
                id="InputPhone"
                name="phone_number"
                className="form-control form-profile-field"
                aria-describedby="phone_number"
                placeholder="+380 XXX XXX XXX"
                onChange={handleInputChange}
                value={formData.phone}
              />
            </div>
            <div className="form-group">
              <label htmlFor="InputCity">
                <Translation path="City of residence" />
              </label>
              <input
                type="text"
                id="InputCity"
                name="city"
                className="form-control form-profile-field"
                aria-describedby="city"
                placeholder={'Your city'}
                onChange={handleInputChange}
                value={formData.location}
              />
            </div>
            <div className="text-center text-md-start">
              <button type="submit" className="btn btn-primary btn-lg btn-radius btn-mobile mt-4">
                <Translation path="Save information" />
              </button>
            </div>
          </form>
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-center">
          <div className="text-center logo-right-block">
            <h2>
              <Translation path="Avatar" />
            </h2>
            {hasAvatar ? (
              <img src={photoSrc} alt="Profile" className="profile-photo mt-3 mb-4" />
            ) : (
              <img src={UserAvatar} alt="Default Avatar" className="profile-photo mt-3 mb-4" />
            )}
            <div className="test">
              <input
                type="file"
                accept=".JPG, .PNG"
                id="uploadAvatarMobile"
                hidden
                onChange={handleFileChange}
              />
              <label
                className="btn btn-outline-dark btn-lg btn-mobile"
                htmlFor="uploadAvatarMobile"
              >
                {' '}
                <img src={UploadIcon} alt="Upload icon" className="button-icon" />
                <Translation path="Upload a photo" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
