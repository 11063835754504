import { Link } from 'react-router-dom';
import { Translation } from 'i18nano';

import Footer from '@components/footer/Footer';
import RegistrationForm from '@components/forms/auth/RegistrationForm';
import Logo from '@assets/images/jobseeker-logo.svg';

const RegistrationPage = () => {
  return (
    <>
      <div className="container">
        <div className="logo-container img">
          <Link to="/">
            <img src={Logo} alt="Logo" className="logo" />
          </Link>
        </div>
        <div className="background"></div>
        <div className="row form-container">
          <h1 className="form-header">
            <Translation path="SING UP" />
          </h1>
          <div className="col-lg-4 col-md-5 col-sm-10">
            <RegistrationForm />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RegistrationPage;
