import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { ReactComponent as IconSettings } from '@assets/images/dashboard/ion_settings-outline.svg';
import { ReactComponent as IconBell } from '@assets/images/dashboard/humbleicons_bell.svg';
import LogoWhite from '@assets/images/dashboard/jobseeker-logo-white.svg';
import usePhotoLoader from '@hooks/photoForm/usePhotoLoader';
import useAvatarStatus from '@hooks/photoForm/checkAvatar';
import UserAvatar from '@assets/images/dashboard/user_image.svg';

const NavbarDashboard = () => {
  const { hasAvatar, avatarData } = useAvatarStatus();
  const { photoSrc, handleAvatarLoad } = usePhotoLoader();

  useEffect(() => {
    if (hasAvatar) {
      handleAvatarLoad(avatarData.replace('data:image/jpeg;base64,', ''));
    }
  }, [hasAvatar, avatarData, handleAvatarLoad]);

  return (
    <div className="bar">
      <div className="logo-block ">
        <Link to="/">
          <img src={LogoWhite} alt="Logo" className="logo-dashboard" />
        </Link>
      </div>
      <div className="right-buttons-block">
        {/* Use Link directly for navigation */}
        <Link to="/dashboard/profile" className="right-button">
          <IconSettings />
        </Link>
        <Link to="/dashboard/profile" className="right-button">
          <IconBell />
        </Link>
        <div>
          {hasAvatar ? (
            <img src={photoSrc} alt="User Avatar" className="right-profile-image" />
          ) : (
            <img src={UserAvatar} alt="User Avatar" className="right-profile-image" />
          )}
        </div>
      </div>
    </div>
  );
};

export default NavbarDashboard;
