import { Translation } from 'i18nano';

import Navbar from '@components/navbar/Navbar';
import Footer from '@components/footer/Footer';

const EmployerPage = () => {
  return (
    <>
      <div className="container">
        <Navbar />
        <h2 className="mb-4 mt-5 title-5">
          <Translation path="How does our platform help employers?" />
        </h2>

        <div className="mb-4">
          <h4 className="title-6">
            <Translation path="Better understanding of the candidate" />
          </h4>
          <p className="text-3">
            <Translation path="Our platform provides detailed information about candidates, including their CV, professional experience, skills and references. This helps you better understand if the candidate is a good fit for your company." />
          </p>
        </div>

        <div className="mb-4">
          <h4 className="title-6">
            <Translation path="Quick finding of candidates" />
          </h4>
          <p className="text-3">
            <Translation path="Our powerful search engine allows you to quickly find candidates with the skills and experience you need. You can apply filters and keywords to pinpoint the right candidates." />
          </p>
        </div>

        <div className="mb-4">
          <h4 className="title-6">
            <Translation path="Minimization of the human factor" />
          </h4>
          <p className="text-3">
            <Translation path="Our platform automates many routine tasks in the hiring process, which helps minimize the human factor and reduces the likelihood of errors. You can use automatic notifications, newsletters and other tools to streamline the process." />
          </p>
        </div>
        <div className="footer-space"></div>
      </div>
      <Footer />
    </>
  );
};

export default EmployerPage;
