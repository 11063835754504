import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '@hooks/auth/useAuth';
import LoadingSpinner from '@components/loading/loadingSpinner';

const ProtectedRoute = () => {
  const isAuthenticated = useAuth();

  if (isAuthenticated === null) {
    return <LoadingSpinner />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
