import { Translation } from 'i18nano';

import Footer from '@components/footer/Footer';
import Navbar from '@components/navbar/Navbar';

const FAQ = () => {
  return (
    <>
      <div className="container">
        <Navbar />
        <h2 className="mb-4  mt-5 title-5">
          <Translation path="Frequently asked questions" />
        </h2>

        <ol>
          <li className="title-6">
            <h3 className="title-6">
              <Translation path="What are the benefits of your resume builder platform?" />
            </h3>
            <p className="text-3">
              <Translation path="Our platform provides a user-friendly resume builder with a variety of templates to help you stand out from the competition. We also provide design advice, important sections and key skills to give you the best chance of success." />
            </p>
          </li>
          <li className="title-6">
            <h3 className="title-6">
              <Translation path="How does job search work on your platform?" />
            </h3>
            <p className="text-3">
              <Translation path="Our platform provides an easy-to-use job search where you can filter results by a variety of criteria, including title, experience, location, and required technology." />
            </p>
          </li>
          <li className="title-6">
            <h3 className="title-6">
              <Translation path="Can I store multiple versions of my resume on your platform?" />
            </h3>
            <p className="text-3">
              <Translation path="Yes, you can store multiple versions of your resume on our platform. This allows you to tailor your resume for different jobs or show different skills and experience, depending on the employer's needs." />
            </p>
          </li>
          <li className="title-6">
            <h3 className="title-6">
              <Translation path="How is my data kept private?" />
            </h3>
            <p className="text-3">
              <Translation path="We place a high priority on the privacy of your data through strict security measures, including storage encryption, strict access controls and regular security audits. Your personal information is anonymized where possible AND you have control over what is visible to potential employers. We comply with data protection laws and if you decide to close your account, we offer options to completely delete your data, ensuring your privacy throughout the job search process." />
            </p>
          </li>
        </ol>
        <div className="footer-space"></div>
      </div>
      <Footer />
    </>
  );
};

export default FAQ;
