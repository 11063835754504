import { toast } from 'react-toastify';
import instance from '@axiosConfig';

const MAX_FILE_SIZE_MB = 5;

const FileUploader = ({ endpoint }) => {
  const uploadToServer = async (file) => {
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      toast.error('Файл занадто великий');
      return;
    }
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await instance.post(endpoint, formData);

      if (response.status === 200) {
        toast.success('Файл завантажено успішно');
      } else {
        toast.error('Помилка в завантаженні файлу');
      }
    } catch (error) {
      toast.error('Помилка в завантаженні файлу');
    }
  };

  return { uploadToServer };
};

export default FileUploader;
