import { Link } from 'react-router-dom';
import { Translation } from 'i18nano';

const AboutUsBlock = () => {
  return (
    <section className="section-space">
      <h2 className="underline-title title-2">
        <span className="d-md-inline d-none">· </span>
        <Translation path="ABOUT US" />
        <span className="d-md-inline d-none"> ·</span>
      </h2>
      <div className="row mb-4 flex-sm-row flex-column-reverse align-items-center">
        <div className="col-sm-6">
          <img
            src={require('@assets/images/home_page/about_us/about_us_first_image.svg').default}
            alt="Про нас"
            className="img-fluid"
          />
        </div>
        <div className="col-sm-6">
          <p className="text-1 text-center text-sm-end">
            <span className="capital-letter c-blue-shade-1">
              <Translation path="W (we)" />
            </span>
            <Translation path="We provide a unique and comprehensive online service for creating resumes and finding IT jobs. Our platform helps IT candidates present their skills and experience in the best possible light and makes job searching simple and efficient." />
          </p>
        </div>
      </div>

      <div className="row mb-4 align-items-center">
        <div className="col-sm-6">
          <p className="text-1 text-center text-sm-start">
            <span className="capital-letter c-blue-shade-2">
              <Translation path="O (our)" />
            </span>
            <Translation path="Our platform uses advanced technologies and tools to ensure high performance and reliability of our service. Our platform is optimized for fast page loading and easy navigation for a comfortable user experience. We also guarantee the security of your personal data and ensure the confidentiality of all information related to the job search." />
          </p>
        </div>
        <div className="col-sm-6">
          <img
            src={require('@assets/images/home_page/about_us/about_us_second_image.svg').default}
            alt="Про нас"
            className="img-fluid"
          />
        </div>
      </div>

      <div className="row flex-sm-row flex-column-reverse align-items-center">
        <div className="col-sm-6 ">
          <img
            src={require('@assets/images/home_page/about_us/about_us_third_image.svg').default}
            alt="Про нас"
            className="img-fluid"
          />
        </div>
        <div className="col-sm-6">
          <p className="text-1 text-center text-sm-end">
            <span className="capital-letter c-blue-shade-3">
              <Translation path="W (we)" />
            </span>
            <Translation path="We are proud that our online service becomes a reliable partner for those who want to develop successfully in the IT field. Whether you're a beginner or a seasoned professional, our platform will provide you with all the tools and resources you need to further develop and succeed in your career." />
          </p>
        </div>
      </div>
      <div className="position-relative mt-5">
        <img
          src={require('@assets/images/home_page/cv_part/cv_example.svg').default}
          alt="Приклад резюме"
          className="img-fluid"
        />
        <div className="cv-overlay">
          <div className="button-container-about-us">
            <div className="title-5 text-center">
              <Translation path="Learn more and join us!" />
            </div>

            <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center mt-sm-5 mt-4 gap-4">
              <Link to="/employer">
                <button className="button employees-button-about">
                  <img
                    src={require('@assets/images/home_page/button_icons/for_employers.svg').default}
                    alt="Іконка для працедавців"
                    className="button-icon"
                  />
                  <Translation path="For employers" />
                </button>
              </Link>

              <Link to="/employee">
                <button className="button seekers-button-about">
                  <img
                    src={require('@assets/images/home_page/button_icons/for_jobseeker.svg').default}
                    alt="Іконка для кандидатів"
                    className="button-icon"
                  />
                  <Translation path="For job seekers" />
                </button>
              </Link>
            </div>
          </div>

          <Link to="/registration">
            <button className="button create-cv-button">
              <img
                src={require('@assets/images/home_page/button_icons/for_jobseeker.svg').default}
                alt="Іконка для створення резюме"
                className="button-icon"
              />
              <Translation path="Create your own resume" />
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AboutUsBlock;
