import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
});

instance.interceptors.request.use(async (config) => {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken) {
    config.headers.Authorization = `${accessToken}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const cookie = document.cookie.split('; ');
      const refreshToken = cookie.find((cookie) => cookie.startsWith('refresh_token='));
      if (refreshToken) {
        try {
          const token = refreshToken.split('=')[1];
          const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/refresh_token/`, {
            headers: {
              Authorization: `${token}`,
            },
          });
          if (response.status === 200) {
            document.cookie = `refresh_token=${response.data.refresh_token}`;
            localStorage.setItem('access_token', response.data.access_token);
            return axios(error.config);
          }
        } catch (refreshError) {
          console.error('Помилка при оновленні токена', refreshError);
        }
      }
    }
    return Promise.reject(error);
  },
);

export default instance;
