import { Link } from 'react-router-dom';
import { ReactComponent as IconProfile } from '@assets/images/dashboard/left_bar_icons/profile_icon.svg';
import { ReactComponent as IconManagers } from '@assets/images/dashboard/left_bar_icons/profile_settings_icon.svg';
import { ReactComponent as IconCandidats } from '@assets/images/dashboard/left_bar_icons/group_icon.svg';
import { ReactComponent as IconEmployees } from '@assets/images/dashboard/left_bar_icons/hourglasses_icon.svg';
import { ReactComponent as IconEvents } from '@assets/images/dashboard/left_bar_icons/calendar_icon.svg';
import { ReactComponent as IconMessages } from '@assets/images/dashboard/left_bar_icons/chat_icon.svg';
import { ReactComponent as IconPayments } from '@assets/images/dashboard/left_bar_icons/dolar_icon.svg';
import { ReactComponent as IconArchive } from '@assets/images/dashboard/left_bar_icons/folder_icon.svg';
import UserAvatar from '@assets/images/dashboard/user_image.svg';
import usePhotoLoader from '@hooks/photoForm/usePhotoLoader';
import useAvatarStatus from '@hooks/photoForm/checkAvatar';
import { useEffect } from 'react';

const SidebarDashboard = () => {
  const { hasAvatar, avatarData } = useAvatarStatus();

  const { photoSrc, handleAvatarLoad } = usePhotoLoader();

  useEffect(() => {
    if (hasAvatar) {
      handleAvatarLoad(avatarData.replace('data:image/jpeg;base64,', ''));
    }
  }, [hasAvatar, avatarData, handleAvatarLoad]);

  return (
    <>
      <div className="left-profile-image">
        {hasAvatar ? (
          <img src={photoSrc} alt="User Avatar" className="left-profile-image-icon" />
        ) : (
          <img src={UserAvatar} alt="User Avatar" className="left-profile-image-icon" />
        )}
      </div>
      <ul className="left-bar-icons-list">
        <li className="left-bar-icons">
          <Link to="/dashboard/profile" className="left-bar-icon active">
            <IconProfile alt="Profile Icon" />
          </Link>
        </li>
        <li className="left-bar-icons">
          <Link to="/dashboard/managers" className="left-bar-icon">
            <IconManagers alt="Managers Icon" />
          </Link>
        </li>
        <li className="left-bar-icons">
          <Link to="/dashboard/soon" className="left-bar-icon">
            <IconCandidats alt="Candidats Icon" />
          </Link>
        </li>
        <li className="left-bar-icons">
          <Link to="/dashboard/soon" className="left-bar-icon">
            <IconEmployees alt="Employees Icon" />
          </Link>
        </li>
        <li className="left-bar-icons">
          <Link to="/dashboard/soon" className="left-bar-icon">
            <IconEvents alt="Events Icon" />
          </Link>
        </li>
        <li className="left-bar-icons">
          <Link to="/dashboard/soon" className="left-bar-icon">
            <IconMessages alt="Messages Icon" />
          </Link>
        </li>
        <li className="left-bar-icons">
          <Link to="/dashboard/soon" className="left-bar-icon">
            <IconPayments alt="Payments Icon" />
          </Link>
        </li>
        <li className="left-bar-icons">
          <Link to="/dashboard/soon" className="left-bar-icon">
            <IconArchive alt="Archive Icon" />
          </Link>
        </li>
      </ul>
    </>
  );
};

export default SidebarDashboard;
