export const formData = {
  firstName: '',
  lastName: '',
  phone: '',
  location: '',
  industry: '',
  position: '',
  experience: '',
  achievements: '',
  salary: '',
  company: '',
  companyIndustry: '',
  companyPosition: '',
  employmentPeriod: '',
  projects: '',
  additionalCompany: '',
  additionalIndustry: '',
  additionalPosition: '',
  additionalEmploymentPeriod: '',
  additionalProjects: '',
  schoolName: '',
  specialization: '',
  educationLevel: '',
  additionalSchool: '',
  additionalSpecialization: '',
  additionalEducation: '',
  courses: '',
  professionalSkills: '',
  hobbies: '',
  futureGoals: '',
  languages: '',
  languageLevel: '',
};
