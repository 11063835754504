import { Translation, useTranslation } from 'i18nano';

const GoalPage = ({ formDataState, handleChange }) => {
  const t = useTranslation();

  return (
    <>
      <h1 className="form-header">
        <Translation path="Goal and career achievements" />
      </h1>
      <form className="questions-form mt-4">
        <div className="mb-2 w-100">
          <label htmlFor="industry" className="form-label">
            <Translation path="What industry or field are you interested in?" />
          </label>
          <select
            id="industry"
            className="form-select input-field"
            name="industry"
            value={formDataState.industry}
            onChange={handleChange}
          >
            <option disabled value="">
              <Translation path="Select from the list" />
            </option>
            <option value="1">
              <Translation path="Information Technology" />
            </option>
            <option value="2">
              <Translation path="Marketing and advertising" />
            </option>
            <option value="3">
              <Translation path="Finances and investments" />
            </option>
          </select>
        </div>
        <div className="mb-2 w-100">
          <label htmlFor="position" className="form-label">
            <Translation path="What position would you like to get?" />
          </label>
          <select
            id="position"
            className="form-select input-field"
            name="position"
            value={formDataState.position}
            onChange={handleChange}
          >
            <option disabled value="">
              <Translation path="Select from the list" />
            </option>
            <option value="1">
              <Translation path="Web developer" />
            </option>
            <option value="2">
              <Translation path="Marketing manager" />
            </option>
            <option value="3">
              <Translation path="Financial analyst" />
            </option>
          </select>
        </div>
        <div className="mb-2 w-100">
          <label htmlFor="experiences" className="form-label">
            <Translation path="What experience do you have in the desired field?" />
          </label>
          <select
            id="experiences"
            className="form-select input-field"
            name="experience"
            value={formDataState.experience}
            onChange={handleChange}
          >
            <option disabled value="">
              <Translation path="Select from the list" />
            </option>
            <option value="1">
              <Translation path="less than 1 year" />
            </option>
            <option value="2">
              1 - 2 <Translation path="years" />
            </option>
            <option value="3">
              <Translation path="years" />3 - 4 <Translation path="years" />
            </option>
            <option value="4">
              <Translation path="5+ years" />
            </option>
          </select>
        </div>
        <div className="w-100">
          <label htmlFor="career" className="form-label">
            <Translation path="Describe your career achievements" />
          </label>
          <textarea
            id="career"
            className="form-control input-textarea"
            rows="3"
            placeholder={t('Input the text')}
            name="achievements"
            value={formDataState.achievements}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="w-100 mobile-field">
          <label htmlFor="salary" className="form-label">
            <Translation path="Your salary expectations" />
          </label>
          <select
            id="salary"
            className="form-select input-field"
            name="salary"
            value={formDataState.salary}
            onChange={handleChange}
          >
            <option disabled value="">
              <Translation path="Select from the list" />
            </option>
            <option value="1">500 - 800$</option>
            <option value="2">1000 - 1500$</option>
            <option value="3">3000 - 5000$</option>
            <option value="4">5000$ +</option>
          </select>
        </div>
      </form>
    </>
  );
};

export default GoalPage;
