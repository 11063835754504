import { Translation } from 'i18nano';

import Footer from '@components/footer/Footer';
import Navbar from '@components/navbar/Navbar';

const PrivacyPolicy = () => {
  return (
    <>
      <div className="container">
        <Navbar />
        <h2 className="mb-4 mt-5 title-5">
          <Translation path="Privacy Policy" />
        </h2>
        <p className="text-3">
          <Translation path="Your privacy is important to us. It is our policy to respect your privacy regarding any information we may collect from you on our website." />
        </p>

        <h4 className="title-6">
          <Translation path="Information we collect" />
        </h4>
        <p className="text-3">
          <Translation path="We may collect personal information such as your name, email address and other information that you voluntarily provide when you interact with our website or services." />
        </p>

        <h4 className="title-6">
          <Translation path="How we use your information" />
        </h4>
        <p className="text-3">
          <Translation path="We may use your personal information to provide and improve our services, send you updates and newsletters, and respond to your inquiries." />
        </p>

        <h4 className="title-6">
          <Translation path="Security" />
        </h4>
        <p className="text-3">
          <Translation path="We take reasonable steps to protect your personal information. However, we cannot guarantee the security of your data." />
        </p>

        <h4 className="title-6">
          <Translation path="Links to third-party resources" />
        </h4>
        <p className="text-3">
          <Translation path="Our website may contain links to third party websites. We have no control over the content and practices of these websites and cannot accept responsibility for their privacy policies." />
        </p>

        <h4 className="title-6">
          <Translation path="Changes to this Privacy Policy" />
        </h4>
        <p className="text-3">
          <Translation path="We reserve the right to update our Privacy Policy from time to time. Any changes will be posted on this page." />
        </p>

        <h4 className="title-6">
          <Translation path="Contact us" />
        </h4>
        <p className="text-3">
          <Translation path="If you have any questions or concerns about our Privacy Policy, please contact us at jobseeker@gmail.com." />
        </p>
        <div className="footer-space"></div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
