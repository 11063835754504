import { Translation } from 'i18nano';

import Footer from '@components/footer/Footer';
import Navbar from '@components/navbar/Navbar';

const AboutUs = () => {
  return (
    <>
      <div className="container">
        <Navbar />
        <h2 className="mb-4 mt-5 title-5">
          <Translation path="ABOUT US" />
        </h2>
        <p className="text-3">
          <Translation path="We provide a unique and comprehensive online service for creating resumes and finding IT jobs. Our platform helps IT professionals present their skills and experience in the best possible light and makes it easy and efficient to search for jobs that match your needs." />
        </p>
        <p className="text-3">
          <Translation path="Our platform uses advanced technologies and tools to ensure high performance and reliability of our services. We optimized the platform for fast page loading and easy navigation for a smooth user experience. We also guarantee the security of your personal data and ensure the confidentiality of all information related to the job search." />
        </p>
        <h3 className="mt-4 title-6">
          <Translation path="We are here to help" />
        </h3>
        <p className="text-3">
          <Translation path="We are proud that our online platform becomes a reliable partner for those who want to develop successfully in the field of IT. Whether you are a beginner or a seasoned professional, our platform will provide you with all the tools and resources you need to further develop and succeed in your career." />
        </p>
        <div className="footer-space"></div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
