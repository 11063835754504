import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: false,
};

export const burgerMenuSlice = createSlice({
  name: 'burgerMenu',
  initialState,
  reducers: {
    open: (state) => {
      state.value = true;
      document.body.style.overflow = 'hidden';
    },
    close: (state) => {
      state.value = false;
      document.body.style.overflow = 'auto';
    },
  },
});

export const { open, close } = burgerMenuSlice.actions;

export default burgerMenuSlice.reducer;
