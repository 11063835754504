import { Link } from 'react-router-dom';
import { Translation } from 'i18nano';

const PageNotFound = () => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="text-center">
        <h1 className="display-1 fw-bold">404</h1>
        <p className="fs-3">
          {' '}
          <span class="text-danger">
            <Translation path="OH!" />
          </span>{' '}
          <Translation path="Page not found." />
        </p>
        <p className="lead">
          <Translation path="Maybe the page you are looking for does not exist." />
        </p>
        <a href="/" className="btn btn-primary text-white">
          <Link to="/">
            <Translation path="Home" />
          </Link>
        </a>
      </div>
    </div>
  );
};

export default PageNotFound;
