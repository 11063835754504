const institutions = [
  {
    id: 1,
    name: 'Київський національний університет імені Тараса Шевченка',
  },
  {
    id: 2,
    name: 'Львівський національний університет імені Івана Франка',
  },
  {
    id: 3,
    name: 'Національний технічний університет України "КПІ"',
  },
  {
    id: 4,
    name: 'Харківський національний університет імені В. Н. Каразіна',
  },
  {
    id: 5,
    name: 'Одеський національний університет імені І. І. Мечникова',
  },
  {
    id: 6,
    name: 'Дніпровський національний університет імені Олеся Гончара',
  },
  {
    id: 7,
    name: 'Національний університет "Львівська політехніка"',
  },
  { id: 8, name: 'Київський політехнічний інститут' },
  {
    id: 9,
    name: 'Національний університет "Києво-Могилянська академія"',
  },
  { id: 10, name: 'Херсонський державний університет' },
  { id: 11, name: 'Донецький національний університет' },
  { id: 12, name: 'Запорізький національний університет' },
  {
    id: 13,
    name: 'Тернопільський національний технічний університет',
  },
  {
    id: 14,
    name: 'Черкаський національний університет імені Богдана Хмельницького',
  },
  { id: 15, name: 'Дрогобицький державний університет' },
  {
    id: 16,
    name: 'Івано-Франківський національний медичний університет',
  },
  { id: 17, name: 'Ужгородський національний університет' },
  {
    id: 18,
    name: 'Луцький національний технічний університет',
  },
  {
    id: 19,
    name: 'Полтавський національний технічний університет імені Юрія Кондратюка',
  },
  { id: 20, name: 'Сумський державний університет' },
  {
    id: 21,
    name: 'Вінницький національний технічний університет',
  },
  {
    id: 22,
    name: 'Донецький національний університет економіки і торгівлі імені Михайла Туган-Барановського',
  },
  {
    id: 23,
    name: 'Житомирський національний аграрний університет',
  },
  {
    id: 24,
    name: 'Східноєвропейський національний університет імені Лесі Українки',
  },
  {
    id: 25,
    name: 'Броварський державний аграрний університет',
  },
  {
    id: 26,
    name: "Кам'янець-Подільський національний університет імені Івана Огієнка",
  },
  {
    id: 27,
    name: 'Національний університет "Острозька академія"',
  },
  {
    id: 28,
    name: 'Національний університет "Житомирська політехніка"',
  },
  {
    id: 29,
    name: 'Луганський національний університет імені Тараса Шевченка',
  },
  {
    id: 30,
    name: 'Черкаський національний університет імені Богдана Хмельницького',
  },
  {
    id: 31,
    name: 'Чернігівський національний технологічний університет',
  },
  {
    id: 32,
    name: 'Національний університет "Львівська політехніка"',
  },
  {
    id: 33,
    name: 'Прикарпатський національний університет імені Василя Стефаника',
  },
  {
    id: 34,
    name: 'Приазовський державний технічний університет',
  },
  {
    id: 35,
    name: 'Національний технічний Університет України "Дніпровська політехніка"',
  },
  { id: 36, name: 'Національний гірничий університет' },
  {
    id: 37,
    name: 'Національний технічний університет "Харківський політехнічний інститут"',
  },
  {
    id: 38,
    name: 'Інститут кіно і телебачення Київського національного університету культури і мистецтв',
  },
  {
    id: 39,
    name: 'Донбаський державний педагогічний університет',
  },
  {
    id: 40,
    name: 'Черкаський державний технологічний університет',
  },
  {
    id: 41,
    name: 'Вінницький національний медичний університет імені М.І. Пирогова',
  },
  { id: 42, name: 'Мукачівський державний університет' },
  {
    id: 43,
    name: 'Національний університет "Львівська політехніка"',
  },
  {
    id: 44,
    name: 'Запорізький національний технічний університет',
  },
  { id: 45, name: 'Хмельницький національний університет' },
  {
    id: 46,
    name: 'Уманський державний педагогічний університет імені Павла Тичини',
  },
  { id: 47, name: 'Дніпровський національний університет' },
  {
    id: 48,
    name: 'Кіровоградський державний педагогічний університет імені Володимира Винниченка',
  },
  {
    id: 49,
    name: 'Луганський державний університет залізничного транспорту імені О. С. Макарова',
  },
  {
    id: 50,
    name: 'Миколаївський національний університет імені В. О. Сухомлинського',
  },
  {
    id: 51,
    name: 'Прикарпатський національний університет імені Василя Стефаника',
  },
  { id: 52, name: 'Університет імені Бориса Грінченка' },
  {
    id: 53,
    name: 'Луцький національний технічний університет',
  },
  {
    id: 54,
    name: 'Полтавський національний педагогічний університет імені Василя Гнатюка',
  },
  {
    id: 55,
    name: 'Харківський національний університет імені В. Н. Каразіна',
  },
  {
    id: 56,
    name: 'Харківський національний медичний університет',
  },
  {
    id: 57,
    name: 'Національний університет "Острозька академія"',
  },
  {
    id: 58,
    name: 'Національний університет "Львівська політехніка"',
  },
  {
    id: 59,
    name: 'Київський національний університет імені Тараса Шевченка',
  },
  {
    id: 60,
    name: 'Національний університет "Києво-Могилянська академія"',
  },
  {
    id: 61,
    name: 'Одеський національний університет імені І. І. Мечникова',
  },
  {
    id: 62,
    name: 'Дніпровський національний університет імені Олеся Гончара',
  },
  { id: 63, name: 'Київський політехнічний інститут' },
  { id: 64, name: 'Херсонський державний університет' },
  { id: 65, name: 'Донецький національний Університет' },
  { id: 66, name: 'Запорізький національний університет' },
  {
    id: 67,
    name: 'Тернопільський національний технічний університет',
  },
  {
    id: 68,
    name: 'Черкаський національний університет імені Богдана Хмельницького',
  },
  { id: 69, name: 'Дрогобицький державний університет' },
  {
    id: 70,
    name: 'Івано-Франківський національний медичний університет',
  },
  { id: 71, name: 'Ужгородський національний університет' },
  {
    id: 72,
    name: 'Луцький національний технічний університет',
  },
  {
    id: 73,
    name: 'Полтавський національний технічний університет імені Юрія Кондратюка',
  },
  { id: 74, name: 'Сумський державний університет' },
  {
    id: 75,
    name: 'Вінницький національний технічний університет',
  },
  {
    id: 76,
    name: 'Донецький національний університет економіки і торгівлі імені Михайла Туган-Барановського',
  },
  {
    id: 77,
    name: 'Житомирський національний аграрний університет',
  },
  {
    id: 78,
    name: 'Східноєвропейський національний університет імені Лесі Українки',
  },
  {
    id: 79,
    name: 'Броварський державний аграрний університет',
  },
  {
    id: 80,
    name: "Кам'янець-Подільський національний університет імені Івана Огієнка",
  },
  {
    id: 81,
    name: 'Національний університет "Острозька академія"',
  },
  {
    id: 82,
    name: 'Національний університет "Житомирська політехніка"',
  },
  {
    id: 83,
    name: 'Луганський національний університет імені Тараса Шевченка',
  },
  {
    id: 84,
    name: 'Черкаський національний університет імені Богдана Хмельницького',
  },
  {
    id: 85,
    name: 'Чернігівський національний технологічний університет',
  },
  {
    id: 86,
    name: 'Національний університет "Львівська політехніка"',
  },
  {
    id: 87,
    name: 'Прикарпатський національний університет імені Василя Стефаника',
  },
  {
    id: 88,
    name: 'Приазовський державний технічний університет',
  },
  {
    id: 89,
    name: 'Національний технічний університет України "КПІ"',
  },
  {
    id: 90,
    name: 'Донбаський державний педагогічний університет',
  },
  {
    id: 91,
    name: 'Черкаський державний технологічний університет',
  },
  {
    id: 92,
    name: 'Вінницький національний медичний університет імені М.І. Пирогова',
  },
  { id: 93, name: 'Мукачівський державний університет' },
  { id: 94, name: 'Кривий Ріг національний університет' },
  {
    id: 95,
    name: 'Маріупольський національний технічний університет',
  },
];

export default institutions;
