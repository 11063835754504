import { Link } from 'react-router-dom';
import { Translation } from 'i18nano';

const Footer = () => {
  return (
    <footer className="text-white py-5">
      <div className="container footer-container">
        <div className="row">
          <div className="col-md-3">
            <h4 className="mb-md-4 mb-3">
              <Translation path="MAIN FUNCTIONS" />
            </h4>
            <ul className="list-unstyled mb-5">
              <li>
                <Link to="/registration" className="text-white">
                  <Translation path="Create your resume" />
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-white">
                  <Translation path="About us" />
                </Link>
              </li>
              <li>
                <Link to="/advantages" className="text-white">
                  <Translation path="Our advantages" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h4 className="mb-md-4 mb-3">
              <Translation path="RESOURCE" />
            </h4>
            <ul className="list-unstyled mb-5">
              <li>
                <Link to="/faq" className="text-white">
                  <Translation path="Questions and answers" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h4 className="mb-md-4 mb-3">
              <Translation path="COMPANY" />
            </h4>
            <ul className="list-unstyled mb-5">
              <li>
                <Link to="/policy" className="text-white">
                  <Translation path="Privacy Policy" />
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-white">
                  <Translation path="Terms of service" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h4 className="mb-md-4 mb-3">
              <Translation path="FOLLOW US" />
            </h4>
            <ul className="list-unstyled">
              <li>
                <a href="#!" className="text-white">
                  <img
                    src={require('@assets/images/home_page/footer/facebook.svg').default}
                    alt="Іконка Facebook"
                    className="footer-icon"
                  />
                </a>
                <a href="#!" className="text-white">
                  <img
                    src={require('@assets/images/home_page/footer/dribble.svg').default}
                    alt="Іконка Dribble"
                    className="footer-icon"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
