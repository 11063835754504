import { Outlet } from 'react-router-dom';

import NavbarDashboard from '@components/dashboard/NavbarDashboard';
import SidebarDashboard from '@components/dashboard/SidebarDashboard';

const DashBoard = () => {
  return (
    <div className="container-fluid p-0">
      <nav>
        <NavbarDashboard />
      </nav>
      <div className="page-box d-flex">
        <aside className="left-bar">
          <SidebarDashboard />
        </aside>
        <div className="content-page p-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
