import { Translation, useTranslation } from 'i18nano';

import cities from '@data/cities';

function PersonalPage({ formDataState, handleChange }) {
  const t = useTranslation();

  return (
    <>
      <h1 className="form-header">
        <Translation path="Personal data" />
      </h1>
      <form className="questions-form mt-4">
        <div className="mb-2 w-100 mobile-field">
          <label htmlFor="fname" className="form-label">
            <Translation path="First Name" />
          </label>
          <input
            type="text"
            id="fname"
            name="firstName"
            value={formDataState.firstName}
            onChange={handleChange}
            className="form-control input-field"
            placeholder={t('Input the text')}
          />
        </div>
        <div className="mb-2 w-100 mobile-field">
          <label htmlFor="lname" className="form-label">
            <Translation path="Last Name" />
          </label>
          <input
            type="text"
            id="lname"
            name="lastName"
            value={formDataState.lastName}
            onChange={handleChange}
            className="form-control input-field"
            placeholder={t('Input the text')}
          />
        </div>
        <div className=" justify-content-center w-100">
          <label htmlFor="pnumber" className="form-label">
            <Translation path="Phone number" />
          </label>
          <input
            type="number"
            id="pnumber"
            name="phone"
            value={formDataState.phone}
            onChange={handleChange}
            className="form-control input-field"
            placeholder="+380 XXX XXX XX"
          />
        </div>
        <div className="w-100 mobile-field">
          <label htmlFor="position" className="form-label">
            <Translation path="City of residence" />
          </label>
          <select
            id="position"
            name="location"
            value={formDataState.location}
            onChange={handleChange}
            className="form-select input-field"
          >
            <option value="" disabled>
              <Translation path="Select from the list" />
            </option>
            {cities.map((city) => (
              <option key={city.id} value={city.name}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
      </form>
    </>
  );
}

export default PersonalPage;
