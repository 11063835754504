import { Link } from 'react-router-dom';
import { Translation } from 'i18nano';
import { useSelector, useDispatch } from 'react-redux';

import { close } from '@redux/burgerMenu/burgerMenuSlice';
import { switchLang } from '@redux/currantLang/currantLangSlice';

function BurgerMenu() {
  const isOpen = useSelector((state) => state.burgerMenu.value);
  const currantLang = useSelector((state) => state.currantLang.value);

  const dispatch = useDispatch();

  return (
    <>
      <div className={`modal-menu ${isOpen ? 'modal-menu-visible' : ''}`}>
        <div className="modal-menu-close-icon" onClick={() => dispatch(close())}>
          <span className="tt"></span>
          <span className="bb"></span>
        </div>
        <ul className="m-0 nav-text text-center">
          <li className="mb-5">
            <Link to="/registration" onClick={() => dispatch(close())}>
              <Translation path="Sing Up" />
            </Link>
          </li>
          <li className="mb-5">
            <Link to="/login" onClick={() => dispatch(close())}>
              <Translation path="Log In" />
            </Link>
          </li>
          <li className="mb-5">
            <Link to="/faq" onClick={() => dispatch(close())}>
              F.A.Q
            </Link>
          </li>
          <li className="mb-5">
            <span
              className={`cursor-pointer ${currantLang === 'en' ? 'c-violet' : ''}`}
              onClick={() => dispatch(switchLang('en'))}
            >
              EN
            </span>{' '}
            /{' '}
            <span
              className={`cursor-pointer ${currantLang === 'uk' ? 'c-violet' : ''}`}
              onClick={() => dispatch(switchLang('uk'))}
            >
              UK
            </span>
          </li>
        </ul>
      </div>
    </>
  );
}

export default BurgerMenu;
