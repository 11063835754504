import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { TranslationProvider } from 'i18nano';

import App from './App';
import { store } from './redux/store';
import translations from './localization/translations';

import 'typeface-inter';
import 'typeface-open-sans';

import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <TranslationProvider translations={translations}>
    <BrowserRouter>
      <Provider store={store}>
        <App tab="home" />
      </Provider>
    </BrowserRouter>
  </TranslationProvider>,
);
