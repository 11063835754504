import { Translation } from 'i18nano';

import Footer from '@components/footer/Footer';
import Navbar from '@components/navbar/Navbar';

const Advantages = () => {
  return (
    <>
      <div className="container ">
        <Navbar />
        <h2 className="mb-4 mt-5 title-5">
          <Translation path="Our advantages" />
        </h2>
        <ol>
          <li className="text-3">
            <p>
              <Translation path="Valuable Resources and Tips: Get helpful tips and resources to help you succeed in your IT job search." />
            </p>
          </li>
          <li className="text-3">
            <p>
              <Translation path="Wide selection of jobs: Search for IT jobs among thousands of current vacancies. We provide access to fresh job vacancies to help you find the perfect job." />
            </p>
          </li>
          <li className="text-3">
            <p>
              <Translation path="Personalization for your needs: Customize your resume to meet the needs of employers and stand out from other candidates. Create a unique resume that reflects your experience and professionalism." />
            </p>
          </li>
          <li className="text-3">
            <p>
              <Translation path="Professional resumes in minutes: Create attractive resumes in just a few simple steps. Our convenient designer will help you quickly stand out among other candidates." />
            </p>
          </li>
        </ol>
        <div className="footer-space"></div>
      </div>
      <Footer />
    </>
  );
};

export default Advantages;
