import { Translation } from 'i18nano';

const AdvantagesBlock = () => {
  return (
    <section className="section-space">
      <div className="text-center">
        <h2 className="underline-title title-2">
          <span className="d-md-inline d-none">· </span>
          <Translation path="OUR ADVANTAGES" />
          <span className="d-md-inline d-none"> ·</span>
        </h2>
        <div className="row g-4 row-cols-1 row-cols-lg-2">
          <div className="feature col px-4">
            <img
              src={
                require('@assets/images/home_page/advantages/advantages_blocks_first.svg').default
              }
              alt="Іконка переваг"
              className="advantages-image"
            />
            <h2 className="title-4 c-blue-shade-2 px-2 px-md-5">
              <Translation path="Valuable resources" />
              <br />
              <Translation path="and advices" />
            </h2>
            <p className="text-2">
              <Translation path="Get helpful tips and resources to help you succeed in your IT job search." />
            </p>
          </div>
          <div className="feature col px-4">
            <div className="feature-icon">
              <img
                src={
                  require('@assets/images/home_page/advantages/advantages_blocks_second.svg')
                    .default
                }
                alt="Іконка переваг"
                className="advantages-image"
              />
            </div>
            <h2 className="title-4 c-blue-shade-2 px-2 px-md-5">
              <Translation path="Wide range" />
              <br />
              <Translation path="of vacancies" />
            </h2>
            <p className="text-2 ">
              <Translation path="Search for IT jobs among thousands of current vacancies. We provide access to fresh vacancies to help you find the perfect job." />
            </p>
          </div>
          <div className="feature col px-4">
            <div className="feature-icon">
              <img
                src={
                  require('@assets/images/home_page/advantages/advantages_blocks_third.svg').default
                }
                alt="Іконка переваг"
                className="advantages-image"
              />
            </div>
            <h2 className="title-4 c-blue-shade-2 px-2 px-md-5">
              <Translation path="Personalization for your needs" />
            </h2>
            <p className="text-2 ">
              <Translation path="Customize your resume to meet the needs of employers and stand out from other candidates. Create a unique resume that reflects your experience and professionalism." />
            </p>
          </div>
          <div className="feature col px-4">
            <div className="feature-icon">
              <img
                src={
                  require('@assets/images/home_page/advantages/advantages_blocks_fourth.svg')
                    .default
                }
                alt="Іконка переваг"
                className="advantages-image"
              />
            </div>
            <h2 className="title-4 c-blue-shade-2 px-2 px-md-5 ">
              <Translation path="Personal resumes in a few minutes" />
            </h2>
            <p className="text-2 ">
              <Translation path="Create attractive resumes in just a few simple steps. Our convenient designer will help you quickly stand out among other candidates." />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvantagesBlock;
