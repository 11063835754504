import { Link } from 'react-router-dom';
import MainImage from '@assets/images/home_page/top-image.svg';
import { Translation } from 'i18nano';

function HeroBlock() {
  return (
    <>
      <section>
        <div className="d-flex background"></div>
        <div className="row flex-column-reverse flex-md-row mt-4 align-items-center">
          <div className="col-md-6 mt-4 mt-md-0">
            <img src={MainImage} alt="MainImage" className="img-fluid" loading="lazy" />
          </div>
          <div className="col-md-6">
            <div className="text-block">
              <h1 className="title-1">
                <Translation path="Your future starts here!" />
              </h1>
              <p className="sub-title-1 mt-3">
                <Translation path="IT professionals and companies in one place" />
              </p>
              <div className="d-flex gap-2 justify-content-center justify-content-xl-end flex-wrap mt-4">
                <Link to="/employer">
                  <button className="button employees-button">
                    <img
                      src={
                        require('@assets/images/home_page/button_icons/for_employers.svg').default
                      }
                      alt="Employee Icon"
                      className="button-icon"
                    />
                    <Translation path="For employers" />
                  </button>
                </Link>

                <Link to="/employee">
                  <button className="button seekers-button">
                    <img
                      src={
                        require('@assets/images/home_page/button_icons/for_jobseeker.svg').default
                      }
                      alt="Employee Icon"
                      className="button-icon"
                    />
                    <Translation path="For job seekers" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroBlock;
