import { Link } from 'react-router-dom';
import { Translation } from 'i18nano';
import { useDispatch, useSelector } from 'react-redux';

import { open } from '@redux/burgerMenu/burgerMenuSlice';
import { switchLang } from '@redux/currantLang/currantLangSlice';

import Logo from '@assets/images/jobseeker-logo.svg';

import BurgerMenu from '@components/burger_menu/BurgerMenu';

const Navbar = () => {
  const currantLang = useSelector((state) => state.currantLang.value);

  const dispatch = useDispatch();

  return (
    <>
      <div className="navbar">
        <div className="navbar-logo">
          <a href="/">
            <img src={Logo} alt="Logo" className="logo" />
          </a>
        </div>
        <div className="navbar-wrapper">
          <div className="d-flex gap-lg-4 align-items-center">
            <div className="navbar-lang me-2">
              <span
                className={currantLang === 'en' ? 'c-violet' : ''}
                onClick={() => dispatch(switchLang('en'))}
              >
                EN
              </span>{' '}
              /{' '}
              <span
                className={currantLang === 'uk' ? 'c-violet' : ''}
                onClick={() => dispatch(switchLang('uk'))}
              >
                UK
              </span>
            </div>
            <ul className="navbar-nav flex-row gap-lg-4">
              <li className="nav-item">
                <Link to="/faq">F.A.Q</Link>
              </li>
              <li className="nav-item">
                <Link to="/login">
                  <Translation path="Log In" />
                </Link>
              </li>
            </ul>
            <Link to="/registration" className="sign-up-button">
              <Translation path="Sing Up" />
            </Link>
          </div>
        </div>
        <div className="navbar-burger" onClick={() => dispatch(open())}>
          <span className="tt"></span>
          <span className="mm"></span>
          <span className="bb"></span>
        </div>
      </div>
      <BurgerMenu />
    </>
  );
};

export default Navbar;
