import { Translation } from 'i18nano';

const Soon = () => {
  return (
    <>
      <h1 className="dashboard-header">
        <Translation path="Soon" />
      </h1>
    </>
  );
};
export default Soon;
